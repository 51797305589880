/* Main container styling */
.wish-list {
  /* Ensure consistent height calculations across browsers */
  min-height: -moz-calc(100vh - 54px);
  min-height: -webkit-calc(100vh - 54px);
  min-height: calc(100vh - 54px);

  section {
    margin-bottom: 40px;
  }

  .login-prompt {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    margin: 20px 0;

    p {
      margin-bottom: 15px;
      font-size: 18px;
    }

    .login-button {
      background-color: #3f51b5;
      color: white;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #303f9f;
      }
    }
  }
}

/* Section titles and headers */
.most-requested-title {
  font-size: 22px;
  font-weight: 400;
  font-family: 'Verdana', sans-serif;
}

/* Video card styling */
.wishlist-video-card {
  width: 180px;
  @media screen and (min-width: 601px) {
    width: 194px;
  }
}

/* Video row container */
.wishlist-video-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 90%;
  max-width: 1100px;
}

/* Table container styling */
.table-container {
  width: 376px;
  padding: 0 8px 16px 8px;
  margin: 0 auto;

  @media screen and (max-width: 601px) {
    width: 97%;
    padding: 0 0 8px 0;
  }

  @media screen and (min-width: 601px) {
    width: 90%;
    padding: 0 0 16px 0;
  }

  @media screen and (min-width: 1020px) {
    width: 1020px;
  }
}

/* Search functionality styling */
.search-container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  .search-input {
    width: 100%;
    padding: 6px;
  }

  .category-label {
    margin-top: 10px;
  }

  .category-select {
    width: 100%;
  }
}

.search-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  .search-button {
    font-size: 16px;

    @media (min-width: 900px) {
      width: 140px;
    }

    @media (min-width: 1050px) {
      width: 200px;
    }

    @media screen and (min-width: 1200px) {
      width: 260px;
    }
  }
}

/* Enhanced carousel styling */
.custom-carousel {
  position: relative;
  min-height: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  width: 100%;
  padding: 0 60px;  /* Create space for navigation buttons */
}

/* Navigation buttons styling */
.prev-wishlist-icon,
.next-wishlist-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 100px;
  max-height: 100px;
  background-color: rgb(62, 111, 202);
  border: none;
  color: white;
  cursor: pointer;
  font-size: 25px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgb(41, 82, 156);
  }
}

.prev-wishlist-icon {
  left: 10px;
}

.next-wishlist-icon {
  right: 10px;
}

/* Responsive navigation adjustments */
@media (max-width: 1400px) {
  .prev-wishlist-icon {
    left: 5px;
  }
  .next-wishlist-icon {
    right: 5px;
  }
}

@media (max-width: 1200px) {
  .wishlist-video-row {
    width: 85%;
  }
}

@media (max-width: 992px) {
  .wishlist-video-row {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .custom-carousel {
    padding: 0 45px;
  }

  .prev-wishlist-icon,
  .next-wishlist-icon {
    width: 35px;
    height: 80px;
  }
}

@media (max-width: 480px) {
  .custom-carousel {
    padding: 0 35px;
  }

  .prev-wishlist-icon,
  .next-wishlist-icon {
    width: 30px;
    height: 60px;
  }
}

/* Items per page configuration */
:root {
  --items-per-page: 5;
}

@media (min-width: 1024px) {
  :root {
    --items-per-page: 5;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  :root {
    --items-per-page: 3;
  }
}

@media (max-width: 767px) {
  :root {
    --items-per-page: 2;
  }
}

@media (max-width: 700px) {
  :root {
    --items-per-page: 1;
  }
}

/* Message components styling */
.empty-state-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 100%;
  text-align: center;
  color: #666;
  margin: auto;
}

.auth-required-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #4b5fc9;
}

.auth-required-text {
  font-size: 1.2rem;
  font-weight: 500;
}

.no-videos-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #4b5fc9;
}

.no-videos-text {
  font-size: 1.2rem;
  font-weight: 500;
}

.no-videos-message {
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin: 20px 0;
  font-size: 18px;
  color: #333;
  font-family: 'Verdana', sans-serif;
}